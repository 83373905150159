import React, { useEffect } from 'react'

import IconsSwitch from 'src/components/IconsSwitch/_index'
import SaibaMais from 'src/components/SaibaMais'

import { brand } from 'src/styles/colors'
import * as S from './style'

const GridCarousel = () => {
  const [ refsPosition, setRefsPosition ] = React.useState<number[]>([])
  const [ carouselDistanceFromLeft, setCarouselDistanceFromLeft ] = React.useState<number>(0)
  const [ scrollLeft, setScrollLeft ] = React.useState<number>(0)

  const gridCarouselRef = React.useRef<HTMLDivElement>(null)
  const cardTwoRef = React.useRef<HTMLDivElement>(null)
  const cardThreeRef = React.useRef<HTMLDivElement>(null)

  const carouselComponentRef = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setCarouselDistanceFromLeft(node.getBoundingClientRect().x)
    }

    return node
  }, [])

  useEffect(() => {
    if (cardTwoRef.current && cardThreeRef.current) {
      setRefsPosition(() => {
        return [
          0,
          cardTwoRef.current?.getBoundingClientRect().left || 0,
          cardThreeRef.current?.getBoundingClientRect().left || 0,
        ]
      })
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (gridCarouselRef.current) {
        setScrollLeft(gridCarouselRef.current.scrollLeft)
      }
    }

    const gridCarousel = gridCarouselRef.current

    if (gridCarousel) {
      gridCarousel.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (gridCarousel) {
        gridCarousel.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const handleArrow = (arrow: 'next' | 'previous') => {
    if (gridCarouselRef.current) {
      const scrollToPosition = refsPosition.find((ref: number) => {
        if (arrow === 'next' && (gridCarouselRef.current && gridCarouselRef.current.scrollLeft + carouselDistanceFromLeft < ref)) {
          return ref
        } else if (arrow === 'previous' && (gridCarouselRef.current && gridCarouselRef.current.scrollLeft + carouselDistanceFromLeft > ref)) {
          return ref
        }
      }) || 0
      gridCarouselRef.current.scrollLeft = scrollToPosition - carouselDistanceFromLeft
    }
  }

  return (
    <S.CarouselComponent ref={carouselComponentRef}>
      <S.GridCarousel ref={gridCarouselRef}>
        <div className='cards-grid'>
          <S.PerdeuACarteira role='img' aria-label='cartões inter'>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>Perdeu a carteira? Com Cartão Mais Protegido, tenha coberturas para perda e roubo do seu cartão além de sorteios de R$5 mil todo mês.</p>
            <SaibaMais
              dataLayer={{
                section: 'dobra_2',
                element_name: 'Saiba Mais',
                section_name: 'Diversas soluções de Seguros na palma da mão',
                redirect_url: 'https://intergo.app/19a9e7c2',
                element_action: 'click button',
              }}
              url='https://intergo.app/19a9e7c2'
            />
          </S.PerdeuACarteira>
          <S.CuidarDoSeuPresente>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>Cuidar do seu presente e do futuro da sua família ficou mais simples. Com Seguro de Vida do Inter, indenizações de até R$ 150 mil, assistência hospitalar e muito mais.</p>
            <SaibaMais
              dataLayer={{
                section: 'dobra_2',
                element_name: 'Saiba Mais',
                section_name: 'Diversas soluções de Seguros na palma da mão',
                redirect_url: 'https://intergo.app/19a9e7c2',
                element_action: 'click button',
              }}
              url='https://intergo.app/19a9e7c2'
            />
          </S.CuidarDoSeuPresente>
          <S.LarDoceLar role='img' aria-label='mulher falando ao telefone'>
            <div className='text-block'>
              <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-white'>Lar doce lar com ainda mais proteção. Seu Seguro Residencial contra roubos, incêndios e muito mais está aqui. </p>
              <SaibaMais
                dataLayer={{
                  section: 'dobra_2',
                  element_name: 'Saiba Mais',
                  section_name: 'Diversas soluções de Seguros na palma da mão',
                  redirect_url: 'https://intergo.app/19a9e7c2',
                  element_action: 'click button',
                }}
                url='https://intergo.app/19a9e7c2' color='#FFFF'
              />
            </div>
          </S.LarDoceLar>
          <S.VamosEvitarPrejuizos ref={cardTwoRef} role='img' aria-label='homem e mulher lado a lado. A mulher segura em uma das mãos um celular e na outra o cartão inter'>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>Vamos evitar prejuízos? Proteja ainda mais suas transações bancárias em casos de celular roubado e até transferências sob coação com Seguro Pix.</p>
            <SaibaMais
              dataLayer={{
              section: 'dobra_2',
              element_name: 'Saiba Mais',
              section_name: 'Diversas soluções de Seguros na palma da mão',
              redirect_url: 'https://inter.co/pra-voce/seguros/seguro-pix/',
              element_action: 'click button',
              }}
              url='https://inter.co/pra-voce/seguros/seguro-pix/'
            />
          </S.VamosEvitarPrejuizos>
          <S.ViajarEmPaz ref={cardThreeRef} role='img' aria-label='cartões inter'>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>Viajar em paz está mais simples com o Inter. Com Seguro Viagem, curta os dias de descanso sem preocupações com assistência 24h. </p>
            <SaibaMais
              dataLayer={{
                section: 'dobra_2',
                element_name: 'Saiba Mais',
                section_name: 'Diversas soluções de Seguros na palma da mão',
                redirect_url: 'https://intergo.app/19a9e7c2',
                element_action: 'click button',
              }}
              url='https://intergo.app/19a9e7c2'
            />
          </S.ViajarEmPaz>
          <S.SeFaltarGrana>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>Se faltar grana, pagamos sua fatura do cartão Inter. Imprevistos acontecem, mas com Proteção Financeira fica mais fácil passar por eles.</p>
            <SaibaMais
              dataLayer={{
                section: 'dobra_2',
                element_name: 'Saiba Mais',
                section_name: 'Diversas soluções de Seguros na palma da mão',
                redirect_url: 'https://intergo.app/19a9e7c2',
                element_action: 'click button',
              }}
              url='https://intergo.app/19a9e7c2'
            />
          </S.SeFaltarGrana>
          <S.SuaVidaMaisTranquila role='img' aria-label='mulher entrando em um carro com um celular na mão'>
            <p className='fs-16 lh-20 fs-lg-18 lh-lg-22 fw-400 text-white'>Sua vida mais tranquila no trânsito só aqui, com Auto Danos a Terceiros.</p>
            <SaibaMais
              dataLayer={{
                section: 'dobra_2',
                element_name: 'Saiba Mais',
                section_name: 'Diversas soluções de Seguros na palma da mão',
                redirect_url: 'https://intergo.app/19a9e7c2',
                element_action: 'click button',
              }}
              url='https://intergo.app/19a9e7c2' color='#FFFF'
            />
          </S.SuaVidaMaisTranquila>
        </div>
      </S.GridCarousel>
      <div>
        <div className='painel-container pt-4'>
          <div onClick={() => handleArrow('previous')}>
            <IconsSwitch lib='interco' customPath='' icon='action-navigation/ic_arrow_left' size='XL' color={brand.primary} />
          </div>
          <div className='d-flex align-items-center'>
            <span className={`stepper-mark ${(scrollLeft === 0) ? 'current-step' : null}`} />
            <span className={`stepper-mark ${((scrollLeft) > 0 && (scrollLeft + carouselDistanceFromLeft) <= refsPosition[1]) ? 'current-step' : null}`} />
            <span className={`stepper-mark ${((scrollLeft + carouselDistanceFromLeft) > refsPosition[1]) ? 'current-step' : null}`} />
          </div>
          <div onClick={() => handleArrow('next')}>
            <IconsSwitch lib='interco' customPath='' icon='action-navigation/ic_arrow_right' size='XL' color={brand.primary} />
          </div>
        </div>
      </div>
    </S.CarouselComponent>
  )
}

export default GridCarousel
