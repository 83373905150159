import styled from 'styled-components'
import { webpImages, IWebpImages } from '../../assets/images/_webp-images'
import { brand, gray, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

const {
  bgPerdeuACarteira,
  bgSuaVidaMaisTranquila,
  bgVamosEvitarPrejuizos,
  bgViajarEmPaz,
  bgLarDoceLar,
}: IWebpImages = webpImages

export const DiversasSolucoesSection = styled.section`
  background-color: white;
  padding: 48px 0;

  .mobile-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
`

// CARDS

export const CarouselComponent = styled.div`
  .painel-container {
    display: flex;
    justify-content: center;
    gap: 24px;
  }
  svg {
    width: 24px;
    height: 24px;
  }

  .stepper-mark {
    width: 8px;
    height: 4px;
    border-radius: 16px;
    background-color: ${orange[200]};
    display: inline-block;
    margin: 0 4px;

    &.current-step {
      width: 16px;
      height: 4px;
      background-color: ${orange[500]};
    }

    transition: all .3s ease-in-out;
  }
`

export const GridCarousel = styled.div`
  overflow-x: scroll;
  scroll-behavior: smooth;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .cards-grid {
    display: grid;
    grid-template-columns: repeat(5, 336px);
    grid-template-rows: 292px 241px;
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    @media ${device.desktopLG} {
      grid-template-columns: repeat(5, 452px);
      grid-template-rows: repeat(2, 322px);
    }

    @media ${device.desktopXL} {
      grid-template-columns: repeat(5, 472px);
      grid-template-rows: repeat(2, 330px);
    }
  }
`

export const PerdeuACarteira = styled.div`
  background-color: ${gray[400]};
  background-repeat: no-repeat;
  background-image: url(${bgPerdeuACarteira.sm});
  background-position: bottom 24px right 24px;
  height: 286px;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.tablet} {
    grid-area: 1 / 1 / 2 / 2;
    height: 100%;
    background-image: url(${bgPerdeuACarteira.md});
    background-position: bottom 8px right 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media ${device.desktopLG} {
    background-image: url(${bgPerdeuACarteira.lg});
  }

  @media ${device.desktopXL} {
    background-position: bottom 0px right 24px;
    background-image: url(${bgPerdeuACarteira.xl});
  }
`

export const CuidarDoSeuPresente = styled.div`
  background-color: ${gray[400]};
  height: 202px;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;

  @media ${device.tablet} {
    height: 100%;
    grid-area: 1 / 2 / 2 / 3;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
`

export const LarDoceLar = styled.div`
  background-color: ${brand.claryOrange};
  background-repeat: no-repeat;
  background-image: url(${bgLarDoceLar.sm});
  background-position: bottom;
  height: 420px;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .text-block {
    @media ${device.tablet} {
      width: 272px;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    }

    @media ${device.desktopLG} {
      width: 442px;
    }
  }

  @media ${device.tablet} {
    height: 100%;
    background-image: url(${bgLarDoceLar.md});
    grid-area: 2 / 1 / 3 / 3;
    display: flex;
    justify-content: flex-end;
    background-position: left;
  }

  @media ${device.desktopLG} {
    background-image: url(${bgLarDoceLar.lg});
  }

  @media ${device.desktopXL} {
    background-image: url(${bgLarDoceLar.xl});
  }
`

export const VamosEvitarPrejuizos = styled.div`
  background-color: ${brand.sand};
  background-repeat: no-repeat;
  background-image: url(${bgVamosEvitarPrejuizos.sm});
  height: 370px;
  border-radius: 16px;
  background-position: center bottom 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${device.tablet} {
    height: 100%;
    grid-area: 1 / 3 / 3 / 4;
    background-image: url(${bgVamosEvitarPrejuizos.md});
    background-position: center bottom 78px;
  }

  @media ${device.desktopLG} {
    background-image: url(${bgVamosEvitarPrejuizos.lg});
    background-position: center bottom 24px;
  }

  @media ${device.desktopXL} {
    background-image: url(${bgVamosEvitarPrejuizos.xl});
  }
`

export const ViajarEmPaz = styled.div`
  background-color: ${gray[400]};
  background-repeat: no-repeat;
  background-image: url(${bgViajarEmPaz.sm});
  background-position: bottom 12px left 0px;
  height: 284px;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${device.tablet} {
    height: 100%;
    grid-area: 1 / 4 / 2 / 5;
    background-image: url(${bgViajarEmPaz.md});
    background-position: bottom right;
    align-items: flex-start;
    justify-content: space-between;
  }

  @media ${device.desktopLG} {
    background-image: url(${bgViajarEmPaz.lg});
    background-position: bottom -28px right 24px;
  }

  @media ${device.desktopXL} {
    background-position: bottom -52px right 24px;
    background-image: url(${bgViajarEmPaz.xl});
  }
`

export const SeFaltarGrana = styled.div`
  background-color: ${gray[400]};
  height: 190px;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${device.tablet} {
    height: 100%;
    grid-area: 2 / 4 / 3 / 5;
    justify-content: space-between;
  }
`

export const SuaVidaMaisTranquila = styled.div`
  background-color: ${brand.claryOrange};
  background-repeat: no-repeat;
  background-image: url(${bgSuaVidaMaisTranquila.sm});
  background-position: bottom 24px center;
  height: 558px;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${device.tablet} {
    height: 100%;
    grid-area: 1 / 5 / 3 / 6;
    background-image: url(${bgSuaVidaMaisTranquila.md});
    background-position: center bottom 48px;
  }

  @media ${device.desktopLG} {
    background-image: url(${bgSuaVidaMaisTranquila.lg});
  }

  @media ${device.desktopXL} {
    background-image: url(${bgSuaVidaMaisTranquila.xl});
  }
`
