export interface IWebpImages {
  [key: string]: {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
}

export const webpImages = {
  backgroundHero: {
    md: 'https://central-imagens.bancointer.com.br/images-without-small-versions/background-md-hero-seguros/image.webp',
    lg: 'https://central-imagens.bancointer.com.br/images-without-small-versions/background-lg-hero-seguros/image.webp',
    xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/background-xl-hero-seguros/image.webp',
  },
  bgPerdeuACarteira: {
    sm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-sm-perdeu-a-carteira/image.webp',
    md: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-md-perdeu-a-carteira/image.webp',
    lg: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-lg-perdeu-a-carteira/image.webp',
    xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-xl-perdeu-a-carteira/image.webp',
  },
  bgSuaVidaMaisTranquila: {
    sm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-sm-sua-vida-mais-tranquila/image.webp',
    md: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-md-sua-vida-mais-tranquila/image.webp',
    lg: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-lg-sua-vida-mais-tranquila/image.webp',
    xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-xl-sua-vida-mais-tranquila/image.webp',
  },
  bgVamosEvitarPrejuizos: {
    sm: "https://central-imagens.bancointer.com.br/images-without-small-versions/bg-sm-vamos-evitar-prejuizos/image.webp",
    md: "https://central-imagens.bancointer.com.br/images-without-small-versions/bg-md-vamos-evitar-prejuizos/image.webp",
    lg: "https://central-imagens.bancointer.com.br/images-without-small-versions/bg-lg-vamos-evitar-prejuizos/image.webp",
    xl: "https://central-imagens.bancointer.com.br/images-without-small-versions/bg-xl-vamos-evitar-prejuizos/image.webp",
  },
  bgViajarEmPaz: {
    sm: "https://central-imagens.bancointer.com.br/images-without-small-versions/bg-sm-viajar-em-paz/image.webp",
    md: "https://central-imagens.bancointer.com.br/images-without-small-versions/bg-md-viajar-em-paz/image.webp",
    lg: "https://central-imagens.bancointer.com.br/images-without-small-versions/bg-lg-viajar-em-paz/image.webp",
    xl: "https://central-imagens.bancointer.com.br/images-without-small-versions/bg-xl-viajar-em-paz/image.webp",
  },
  bgLarDoceLar: {
    sm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-sm-lar-doce-lar/image.webp',
    md: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-md-lar-doce-lar/image.webp',
    lg: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-lg-lar-doce-lar/image.webp',
    xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-xl-lar-doce-lar/image.webp',
  },
  bannerCuidarBemDaSaude: {
    xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/banner-cuidar-da-saude-tambem-e-inter/image.webp',
  },
  bgSolucoesProSeuNegocio: {
    sm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-solucoes-pro-seu-negocio-sm/image.webp',
    md: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-solucoes-pro-seu-negocio-md/image.webp',
    lg: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-solucoes-pro-seu-negocio-lg/image.webp',
    xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bg-solucoes-pro-seu-negocio-xl/image.webp',
  },
  bgComoFuncoinamOsSegurosInter: {
    xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/banner-como-funcionam-os-seguros-inter/image.webp',
  },
  bgTaNaBocaDoPovo: {
    xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/banner-ta-na-boca-do-povo/image.webp',
  },
}
